/** 
 * @desc 异步字典接口 
 * */

 //@ts-ignore
 import { $get, $post, $dataPost, $put } from "../../../http/axios"
/** @desc 字典名称枚举 */
export enum DictionaryName{
    /** @desc 产业基金申报状态 */
    INDUSTRY_FUND_DECLARE_STATUS = 'industry_fund_declare_status',
    /** 产业类别 */
    INDUSTRY_CATEGORY = 'industry_category',
    /** 空间资源-申请状态 */
    ENT_APPLY_STATUS = 'ent_apply_status',
    /** 空间资源-租赁状态 */
    ENT_LEASE_STATUS = 'ent_lease_status',
}
type DictionaryItem={
    /** @desc 字典名称 */
    dictDesc:string,
    /** @desc 字典项描述文字 */
    itemDesc:string,
    /** @desc 字典项值 */
    itemValue:string

}
export async function getDictionary(name:DictionaryName | keyof DictionaryName){
   let {result} = await $post(`/dict/list?dictKey=${String(name)}&selectType=1`)
   return result.reduce((dic:Object,item:DictionaryItem)=>{
     return {...dic,[item.itemValue]:item.itemDesc}
   },{})
}