<template>
  <div class="wrapper">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <!-- <el-breadcrumb-item >资金扶持</el-breadcrumb-item> -->
      <el-breadcrumb-item :to="{ path: '/IndustrialFund' }"
        >产业基金</el-breadcrumb-item
      >
      <el-breadcrumb-item>项目清单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="container">
      <div class="title-bar">
        <div class="title fl">项目清单</div>
        <el-button type="primary" class="btn" @click="handleToDeclare('IFDeclare')">
          申报项目
        </el-button>
      </div>
      <div class="content">
          <el-table :data="tableQuerytor.list">
              <el-table-column label="申请机构名称" prop="applicantManageOrgName"></el-table-column>
              <el-table-column label="拟合作设立基金名称" prop="willCooperateFundName"></el-table-column>
              <el-table-column label="拟合作设立基金规模(万元)" prop="willCooperateFundScale"></el-table-column>
              <el-table-column label="拟申请额度(万元)" prop="willApplicantAmount"></el-table-column>
              <el-table-column label="申请时间" prop="createTime"></el-table-column>
              <el-table-column label="状态" prop="industryFundDeclareStatus" :formatter="(row)=>statusDict.get(row.industryFundDeclareStatus)"></el-table-column>
              <el-table-column label="操作"  >
                 <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click="handleToDetail(scope.row.id)">查看</el-button>
                 </template>
              </el-table-column>
          </el-table>
          <el-pagination  :current-page.sync="tableQuerytor.pager.page" background layout="total,prev, pager ,next" :page-size="tableQuerytor.pager.size" :total="tableQuerytor.pager.total"></el-pagination>
          <!-- <el-pagination :key="'nmsl'" :total="tableQuerytor.pager.total" :current-page="tableQuerytor.pager.page" :page-size="tableQuerytor.pager.size" layout="horizontal"></el-pagination> -->
      </div>
    </div>
  </div>
</template>

<script>
import ListQuerytor from '@/common/ListQuerytorTs';
import { getIndustryFundDeclareList } from '@/common/api/modules/industrialFund';
import Dictionary from "@/common/dictionary"
import { DictionaryName } from '@/common/api/modules/dictionary';
export default {
    data(){
        return {
            tableQuerytor:new ListQuerytor({
                api:getIndustryFundDeclareList,
            }),
            statusDict:new  Dictionary(DictionaryName.INDUSTRY_FUND_DECLARE_STATUS)
        }
    },
    async mounted(){
        this.tableQuerytor.query();
        // let value = await this.dict.get("0");
        // console.log(value)
    },
    methods:{
        handleToDeclare(name){
            this.$router.push({name})
        },
        handleToDetail(id){
           this.$router.push({name:"IFDeclare",params:{id}})
        }
    }

};
</script>

<style lang="scss">
.container {
    background: white;
    padding:20px;
.title-bar{
    border-bottom: 1px solid #33333330;
    padding: 20px;
      .title {
    font-family: "MicrosoftYaHei-Bold";
    font-size: 26px;
    font-weight: 600;
    color: #333333;
    // line-height: 36px;
    position: relative;
    padding-left: 14px;
    display: flex;
    align-items: center;
    margin-right: 14px;
    &:before {
      content: "";
      display: inline-block;
      width: 4px;
      height: 20px;
      background: #4393f8;
      border-radius: 3px;
      position: absolute;
      top: 10px;
      left: 0;
    }
  }
}

  .content{
      padding-top: 20px;
  }
}
</style>
